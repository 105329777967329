<template>
    <div class="dfw mobile-contain">
        <h2>Interactive D-3A Chat</h2>
    </div>

    <preloader v-if="uploadPending != false" />

    <div id="mainChatContainer">
        <div class="document-fields">
            <div class="columns">
                <div class="field column">
                    <div class="card">
                        <div class="card-content">
                            <label class="label" for="note-title">What would you like to know about the 25/26 D-3As?</label>
                            <div class="send-message">
                                <input v-model="currentMessage" type="text" v-on:keyup.enter="sendMessage(currentMessage, $event)" class="document-query" />
                                <button class="button ask-button" @click="sendMessage(currentMessage, $event)">{{ buttonLabel }}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="messageBox" v-if="messages.length > 0">
            <template v-for="message in messages" :key="message">
                <div :class="message.from=='user' ? 'messageFromUser' :'messageFromChatGpt'">
                    <span class="responseAvatar" v-if="message.from=='user'">
                        <svg class="svg-inline--fa fa-lg" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="user" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" aria-label="Browse Contacts icon" data-v-aceaefd2=""><path class="" fill="currentColor" d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0S96 57.3 96 128s57.3 128 128 128zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z"></path></svg>
                    </span>
                    <span class="responseAvatar" v-if="message.from!='user'">
                        <img src="/favicons/apple-touch-icon.png" alt="" />
                    </span>
                    <div class="responseBox">
                        <span class="responseName">{{message.from=='user' ? 'You' : 'GovAI'}}</span>
                        <TypewriterText ref="typewriter" :data="message.data" @pageClicked="goToPage" @typingStopped="handleTypingStopped"></TypewriterText>
                    </div>
                </div>
            </template>
            <div id="loadingChatMessage" v-if="loadingChatReply">
                <div class="dot-typing"></div>
            </div>
        </div>
    </div>
    <div v-if="isDebbuger">
        <h6>DEBUG:</h6>
        <pre v-html="debuggerMessage"></pre>
    </div>
    <!-- End Page: pages/chat-bot -->
</template>

<script>
import RequestHandler from "@/handler/RequestHandler";
import Preloader from "./preloader.vue";
import {ref, watch, computed} from "vue";
import { useStore } from "vuex";
import CustomSelect from "./custom-select";
import MarkdownIt from "markdown-it";
import InfoCard from "./info-card";
import TagAgency from "./tag--agency";
import TypewriterText from "./typewriter-text";

export default {
    name: "chat-bot",
    props: [],
    components: {
        Preloader,
        CustomSelect,
        InfoCard,
        TagAgency,
        TypewriterText
    },
    emits: ['pageClicked'],
    setup(props, { emit }) {
        const messages = ref([]);
        const selectedDocument = ref(localStorage.getItem('docId'));
        const selectedDocumentLocation = ref(localStorage.getItem('docLocationId'));
        const selectedDocumentName = ref(localStorage.getItem('docName') || null);
        const currentMessage = ref(null);
        const loadingChatReply = ref(false);
        const isDebbuger = ref(false);
        const debuggerMessage = ref([]);
        const store = useStore();
        const filesRef = ref(null);
        const uploadPending = ref(false);
        const uploadError = ref(null);
        const documentList = ref([]);
        const documentDetailLoading = ref(true);
        const documentDetail = ref(null);
        const waitingChatReply = ref(false);
        const responseText = ref(null);
        const keywordMap = ref(null);
        const requestCount = ref(0);
        const changeFiles = (event) => {
            if(event.target.files) {
                uploadDocument();
            }
        }

        const typewriter = ref(null);
        const buttonLabel = ref("Ask GovAI");
        const isSending = ref(false);

        const goToPage = (pageNumber) => {
            emit('pageClicked', pageNumber, keywordMap.value['page_'+pageNumber]);
        }

        const handleTypingStopped = () => {
            buttonLabel.value = "Ask GovAI";
            isSending.value = false;
        }

        const sendMessage = async(message) => {
            requestCount.value++;
            event.target.blur();
            if (isSending.value) {
                buttonLabel.value = "Ask GovAI";
                isSending.value = false;
                typewriter.value[0].stopTyping();
            } else {
                buttonLabel.value = "Stop";
                isSending.value = true;
            }
            if (!loadingChatReply.value && isSending.value) {
                waitingChatReply.value = false;
                loadingChatReply.value = true;
                const tempMessages = messages.value;
                tempMessages.push({
                    from: 'user',
                    data: message,
                    goToPage: goToPage
                });
                messages.value = tempMessages;
                currentMessage.value = '';
                debuggerMessage.value = [];

                let uri = window.location.href.split('?');
                if(uri.length == 2) {
                    let qparams = uri[1].split('=');
                    if (qparams[0] == 'debug') {
                        isDebbuger.value = qparams[1] == 'true' ? true : false;
                    }
                }
                if (message.toLowerCase() == 'clear') {
                    requestCount.value = 0;
                }
                RequestHandler.loadD3AChatResult(message, isDebbuger.value, selectedDocument.value, store.getters.csrf, requestCount.value).then(response => {
                    response.data.map((msg) => {
                        responseText.value = msg.result && msg.result != null ? msg.result : '';
                        keywordMap.value = msg.keywords;
                        waitingChatReply.value = true;
                        messages.value.push({goToPage: goToPage, from: 'assistant', data: (msg.result && (msg.result == null || msg.result == 'null')  ? '' : msg.result)});
                        console.log(messages);
                        debuggerMessage.value = JSON.stringify(msg.debugData, null, 2);

                        debuggerMessage.value = JSON.stringify(msg.result, null, 2);

                    });
                    loadingChatReply.value = false;
                })
                .catch(error => {
                    loadingChatReply.value = false;
                    console.error(error);
                });
            }
        };

        const uploadDocument = () => {
            uploadPending.value = true;
            RequestHandler.addNewDocument({
                files: filesRef.value.files[0],
            }, store.getters.csrf).then((data) => {
                data.map((document) => {
                    if (document && document.result && document.result.id) {
                        selectedDocument.value = document.result.id;
                        documentList.value.push(document.result);
                    } else {
                        uploadError.value = document.error;
                    }
                });
                uploadPending.value = false;
            }).catch((error) => {
                console.error(error);
                uploadError.value = error.message || error;
                uploadPending.value = false;
            });
        };

        return {
            sendMessage,
            uploadDocument,
            messages,
            currentMessage,
            loadingChatReply,
            isDebbuger,
            debuggerMessage,
            filesRef,
            uploadPending,
            documentList,
            selectedDocument,
            selectedDocumentName,
            changeFiles,
            waitingChatReply,
            responseText,
            keywordMap,
            documentDetailLoading,
            documentDetail,
            goToPage,
            requestCount,
            buttonLabel,
            isSending,
            typewriter,
            handleTypingStopped,
        };
    }
}
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/_variables.scss";

    .send-message {
        display: flex;
        gap: 15px;        
    }

    .send-message input {
        flex: 1 0 0;
        padding: 10px;
    }

    .send-message button {
        flex: 0 0 auto;
        height: 40px;
        padding: 10px;
    }

    .messageBox {
        min-height: 150px;
        background-color: #fff;
        border-radius: 0.25rem;
        padding: 15px;
        box-shadow: 0 0.5em 1em -0.125em rgba(0,0,0,.1), 0 0 0 1px rgba(0,0,0,.02);
        margin-bottom: 2px;
    }

    .messageFromUser,
    .messageFromChatGpt {
        text-align: left;
        margin-bottom: 15px;
        padding-left: 52px;
        position: relative;
    }

    .responseAvatar {
        position: absolute;
        left: 0;
        top: 0;
        width: 44px;
        height: 44px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: aliceblue;
        overflow: hidden;
    }

    .responseBox {
        background-color: white;
        border-radius: 10px;
        padding: 4px 16px;
    }

    .messageFromUser .responseBox {
        background-color: aliceblue;
        border-radius: 10px;
        padding: 10px 16px;
    }

    .responseName {
        font-weight: bold;
        padding-bottom: 4px;
        display: block;
        line-height: 1.1;
    }

    .messageFromUser .responseName {
        padding-bottom: 2px;
    }

    .responseLineHidden {
        display: none;
    }

    .card__info__label {
        float: left;
        margin-right: 10px;
        display: block;
        width: 100%;
        margin-bottom: 5px!important;
    }

    $dot-width: 10px !default;
    $dot-height: 10px !default;
    $dot-radius: $dot-width * .5;
    $dot-color: $blue--med !default;
    $dot-bg-color: $dot-color !default;
    $dot-before-color: $dot-color !default;
    $dot-after-color: $dot-color !default;

    $dot-spacing: $dot-width + $dot-width * .5 !default;

    @mixin dot(
        $width: $dot-width,
        $height: $dot-height,
        $radius: $dot-radius,
        $bg-color: $dot-bg-color,
        $color: $dot-color
    ) {
        width: $width;
        height: $height;
        border-radius: $radius;
        background-color: $bg-color;
        color: $color;
    }

    $left-pos: -9999px;
    $x1: - $left-pos - $dot-spacing;
    $x2: - $left-pos;
    $x3: - $left-pos + $dot-spacing;

    .dot-typing {
        margin: 0 auto;
        position: relative;
        left: -9999px;
        width: 10px;
        height: 10px;
        border-radius: 5px;
        background-color: $blue--med;
        color: $blue--med;
        box-shadow: 9984px 0 0 0 $blue--med, 9999px 0 0 0 $blue--med, 10014px 0 0 0 $blue--med;
        animation: dot-typing 1.5s infinite linear;
    }

    @keyframes dot-typing {
        0% {
            box-shadow:
            $x1 0 0 0 $dot-before-color,
            $x2 0 0 0 $dot-color,
            $x3 0 0 0 $dot-after-color;
        }

        16.667% {
            box-shadow:
            $x1 -10px 0 0 $dot-before-color,
            $x2 0 0 0 $dot-color,
            $x3 0 0 0 $dot-after-color;
        }

        33.333% {
            box-shadow:
            $x1 0 0 0 $dot-before-color,
            $x2 0 0 0 $dot-color,
            $x3 0 0 0 $dot-after-color;
        }

        50% {
            box-shadow:
            $x1 0 0 0 $dot-before-color,
            $x2 -10px 0 0 $dot-color,
            $x3 0 0 0 $dot-after-color;
        }

        66.667% {
            box-shadow:
            $x1 0 0 0 $dot-before-color,
            $x2 0 0 0 $dot-color,
            $x3 0 0 0 $dot-after-color;
        }

        83.333% {
            box-shadow:
            $x1 0 0 0 $dot-before-color,
            $x2 0 0 0 $dot-color,
            $x3 -10px 0 0 $dot-after-color;
        }

        100% {
            box-shadow:
            $x1 0 0 0 $dot-before-color,
            $x2 0 0 0 $dot-color,
            $x3 0 0 0 $dot-after-color;
        }
    }
</style>
